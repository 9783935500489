// COLORS

$blue-grey-50: #ECEFF1;
$blue-grey-100: #CFD8DC;
$blue-grey-200: #B0BEC5;
$blue-grey-300: #90A4AE;
$blue-grey-400: #78909C;
$blue-grey-500: #607D8B;
$blue-grey-600: #546E7A;
$blue-grey-700: #455A64;
$blue-grey-800: #37474F;
$blue-grey-900: #263238;

$pink: #E91E63;
$pink-50: #FCE4EC;
$pink-100: #F8BBD0;
$pink-200: #F48FB1;
$pink-300: #F06292;
$pink-400: #EC407A;
$pink-500: #E91E63;
$pink-600: #D81B60;
$pink-700: #C2185B;
$pink-800: #AD1457;
$pink-900: #880E4F;
$pink-A100: #FF80AB;
$pink-A200: #FF4081;
$pink-A400: #F50057;
$pink-A700: #C51162;

$blue-50: #E1F5FE;
$blue-100: #B3E5FC;
$blue-200: #81D4FA;
$blue-300: #4FC3F7;
$blue-400: #29B6F6;
$blue-500: #03A9F4;
$blue-600: #039BE5;
$blue-700: #0288D1;
$blue-800: #0277BD;
$blue-900: #01579B;
$blue-A100: #80D8FF;
$blue-A200: #40C4FF;
$blue-A400: #00B0FF;
$blue-A700: #0091EA;

$light-blue: #03A9F4;
$light-blue-50: #E1F5FE;
$light-blue-100: #B3E5FC;
$light-blue-200: #81D4FA;
$light-blue-300: #4FC3F7;
$light-blue-400: #29B6F6;
$light-blue-500: #03A9F4;
$light-blue-600: #039BE5; // 00E8FF
$light-blue-700: #0288D1;
$light-blue-800: #0277BD;
$light-blue-900: #01579B;
$light-blue-A100: #80D8FF;
$light-blue-A200: #40C4FF;
$light-blue-A400: #00B0FF;
$light-blue-A700: #0091EA;

$grey-50: #FAFAFA;
$grey-100: #F5F5F5;
$grey-200: #EEEEEE;
$grey-300: #E0E0E0;
$grey-400: #BDBDBD;
$grey-500: #9E9E9E;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;

$palette-blue: $light-blue-A400;
$palette-orange: #FF9947;
$palette-red: #D1335B;
$palette-green: #03BD5B;
$palette-lemon: #9DBF15;
$palette-purpple: #A939B9;
$palette-yellow: #FFEB3B;
$palette-pink: $pink-500;

$warning-color: $palette-orange;
$error-color: $palette-red;
$success-color: $palette-green;
$info-color: $palette-blue;

$white: #ffffff;
$black: #000000;
$dark: #111111;

// SOCIAL COLORS

$facebook-color: #3c5a9c;
$twitter-color: #35a1f2;
$instagram-color: #262626;
$youtube-color: #ff0000;
$linkedin-color: #0073bc;
$twitch-color: #9147ff;

// DEFAULT

$primary-color: $palette-blue;
$secondary-color: $palette-pink;

$text-default-color: $dark;
$text-help-color: $blue-grey-400;

// GRADIENT

// dark blue pink
$default-gradient: linear-gradient(120deg, $primary-color, $secondary-color 100%);

// SIZING

$border-radius: 2px;
$border-radius-md: 3px;

$height-toolbar-md: 52px;

$gutter: 16px;
$gutter-md: $gutter * 2;
$gutter-lg: $gutter-md * 2;
$gutter-sm: $gutter / 2;
$gutter-xs: $gutter-sm / 2;


// RESPONSIVE

$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

// FONT SIZE

$font-size-1: 1em;
$font-size-2: 1.8em;
$font-size-3: 2em;
$font-size-4: 2.5em;
$font-size-5: 3em;
$font-size-6: 4em;
