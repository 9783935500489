@import './variables';

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  1%, 2% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  3%, 5%, 7%, 9% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  4%, 6%, 8% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  10% {
    transform: scale3d(1, 1, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@mixin animated($animation-name) {
  animation-name: $animation-name;
  animation-delay: 0;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;

  @media (print), (prefers-reduced-motion) {
    animation: unset !important;
    transition: none !important;
  }
}