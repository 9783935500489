

























.logo-karasu {
  width: 100%;
  height: 100%;
}
