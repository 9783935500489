









































@import '../style/main';

$side-panel-width: 380px;

.slide-enter-active .ui-side-panel__panel {
  transition: all .3s ease;
}

.slide-leave-active .ui-side-panel__panel {
  transition: all .3s ease;

}

.slide-enter, .slide-leave-to {
  opacity: 0;
  .ui-side-panel__panel {
    transform: translateX($side-panel-width);
  }
}

.ui-side-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  pointer-events: none;
  -webkit-touch-callout: none;
  z-index: 11;

  &__overlay {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: auto;
    transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1);

    background-color: transparentize($dark, 0.65%);
  }

  &__panel {
    pointer-events: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 12;
    height: 100%;
    max-height: 100%;
    width: 100%;

    background-color: $white;

    @media screen and (min-width: $screen-xs) {
      width: $side-panel-width;
    }

    @include card(5);

    overflow-y: auto;
    transition: 0.3s ease-in-out;
  }

  &__header {
    .mx-closable-close-button {
      padding-top: $gutter;
    }
  }

  &__content {
    padding: 0 $gutter;
  }
}
