
// name="slide-fade"
// Fade with small translate

.slide-fade-enter-active {
  opacity: 1;
  transition: all .3s ease;
}
.slide-fade-leave-active {
  opacity: 1;
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(16px);
  opacity: 0;
}

.slide-bottom-top-fade-enter-active {
  opacity: 1;
  transition: all .3s ease;
}
.slide-bottom-top-fade-leave-active {
  opacity: 1;
  transition: all .3s ease;
}
.slide-bottom-top-fade-enter, .slide-bottom-top-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.slide-top-bottom-fade-enter-active {
  opacity: 1;
  transition: all .3s ease;
}
.slide-top-bottom-fade-leave-active {
  opacity: 1;
  transition: all .3s ease;
}
.slide-top-bottom-fade-enter, .slide-bottom-top-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}


// name="slide-bottom-top"
// Slide from bottom to top

.slide-bottom-top-enter-active {
  transition: transform .3s ease;
}
.slide-bottom-top-leave-active {
  transition: transform .3s ease;
}
.slide-bottom-top-enter, .slide-bottom-top-leave-to {
  transform: translateY(100%);
}


// name="slide-right"
// Slide from right to left

.slide-right-enter-active {
  transition: transform .3s ease;
}
.slide-right-leave-active {
  transition: transform .3s ease;
}
.slide-right-enter, .slide-right-leave-to {
  transform: translateX(100%);
}


// name="slide-left"
// Slide from right to left

.slide-left-enter-active {
  transition: transform .3s ease;
}
.slide-left-leave-active {
  transition: transform .3s ease;
}
.slide-left-enter, .slide-left-leave-to {
  transform: translateX(-100%);
}