


























































































.logo {
  filter: drop-shadow(0 0 .75em rgba(0,0,0,.3));
}
