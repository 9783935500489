












































































@import '../style/main';

.ui-menu {
  position: relative;
  display: inline-block;

  &-separator {
    width: auto;
    margin: 0 $gutter;
    border-bottom: 1px solid $grey-100;
  }

  &__content {
    position: absolute;
    z-index: 1;
    min-width: 150px;
    max-width: 300px;
    top: 100%;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 3px;
    margin-top: $gutter-sm;

    @include card(2);

    &.condensed {
      bottom: 80%;
      & * {
        font-size: 0.7em !important;
      }
    }

    & .ui-button {
      text-align: left;
      border-radius: 0;
    }

    &.fit {
      width: auto;
    }

    &.top {
      top: inherit;
      bottom: 100%;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}
