@import 'fonts';
@import 'reset';
@import 'variables';

// Global style

html, body {
  width: 100%;
  height: 100%;
  min-width: 318px;
  min-height: 318px;
  position: relative;
}

body {
  background: $dark;
  background-attachment: fixed;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  line-height: 1.2em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: auto;
  color: $white;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  padding-bottom: 0.5em;
  line-height: 1em;
  color: inherit;
}

h1 {
  font-weight: 600;
  font-size: $font-size-6;
  color: inherit;

  text-transform: uppercase;
}

h2 {
  font-weight: 500;
  font-size: $font-size-4;
  color: inherit;
}

h3 {
  font-weight: 500;
  font-size: $font-size-3;
  color: inherit;
}

h4 {
  font-weight: 500;
  font-size: $font-size-2;
  color: inherit;
}

.text-size-1 {
  font-size: $font-size-1;
}

.text-size-2 {
  font-size: $font-size-2;
}

.text-size-3 {
  font-size: $font-size-3;
}

.text-size-4 {
  font-size: $font-size-4;
}

.text-size-5 {
  font-size: $font-size-5;
}

hr {
  border: 0;
  height: 1px;
  background-color: $grey-200; 
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.text-white {
  color: $white;
}
.text-dark {
  color: $dark;
}

.text-help {
  color: $grey-300;
}
.text-error {
  color: $error-color;
}

.block-help {
  @include card(1);

  margin: $gutter 0;
  padding: $gutter;
  background-color: $dark;
  color: $white;
  border-radius: 5px;

  strong {
    color: $pink-A100;
  }
}

.card-style {
  background-color: $dark;
  color: $white;
  padding: $gutter;
  border-radius: $gutter-xs;

  h4 {
    text-transform: uppercase;
  }

  @include card(2);
}

ul.inline-list {
  position: relative;
  list-style-type: none;
  margin-bottom: $gutter;

  li {
    margin: 0 $gutter 0 0;
    padding: 0;
    display: inline-block;
    position: relative;
  }

  &.column-mobile {
    @media screen and (max-width: $screen-xs) {
      li {
        display: block;
      }
    }
  }

  &.center-list {
    margin: 0;
    padding: $gutter-sm;

    li {
      margin-left: $gutter-sm;
      margin-right: $gutter-sm;
    }
  }
}

.placeholder {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  padding: 0 $gutter-xs;
  color: $grey-300;
  text-align: center;
}


// Floats

.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.reset-float {
  clear: both;
}


// Tag

.tag {
  background-color: transparentize($white, .66);
  color: $dark;
  padding: $gutter-xs $gutter-sm;
  border-radius: 2px;
  line-height: 1.2em;
  font-size: 0.8rem;
  display: inline-block;

  &.tag-dark {
    background-color: transparentize($grey-700, .33);
    color: $white;
  }
}


// Margins

.mrg-bottom-lg {
  margin-bottom: $gutter-lg !important;
}
.mrg-bottom-md {
  margin-bottom: $gutter-md !important;
}
.mrg-bottom {
  margin-bottom: $gutter !important;
}
.mrg-bottom-sm {
  margin-bottom: $gutter-sm !important;
}
.mrg-bottom-xs {
  margin-bottom: $gutter-xs !important;
}
// Margins

.mrg-top-lg {
  margin-top: $gutter-lg !important;
}
.mrg-top-md {
  margin-top: $gutter-md !important;
}
.mrg-top {
  margin-top: $gutter !important;
}
.mrg-top-sm {
  margin-top: $gutter-sm !important;
}
.mrg-top-xs {
  margin-top: $gutter-xs !important;
}

.mrg-lg {
  margin: $gutter-lg !important;
}
.mrg-md {
  margin: $gutter-md !important;
}
.mrg {
  margin: $gutter !important;
}
.mrg-sm {
  margin: $gutter-sm !important;
}
.mrg-xs {
  margin: $gutter-xs !important;
}

// Paddings

.pddg-bottom-lg {
  padding-top: $gutter-lg;
}
.pddg-bottom-md {
  padding-top: $gutter-md;
}
.pddg-bottom {
  padding-top: $gutter;
}
.pddg-bottom-sm {
  padding-top: $gutter-sm;
}
.pddg-bottom-xs {
  padding-top: $gutter-xs;
}
// Paddings

.pddg-top-lg {
  padding-top: $gutter-lg;
}
.pddg-top-md {
  padding-top: $gutter-md;
}
.pddg-top {
  padding-top: $gutter;
}
.pddg-top-sm {
  padding-top: $gutter-sm;
}
.pddg-top-xs {
  padding-top: $gutter-xs;
}

.pddg-lg {
  padding: $gutter-lg;
}
.pddg-md {
  padding: $gutter-md;
}
.pddg {
  padding: $gutter;
}
.pddg-sm {
  padding: $gutter-sm;
}
.pddg-xs {
  padding: $gutter-xs;
}

.box-center {
  margin: auto !important;
}

// Flex stuff

.box-spacing {
  flex: 1;
}

.box-container {
  position: relative;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.box-container-sm {
  @extend .box-container;
  max-width: 800px;
}

.box-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: start;

  &-center {
    align-items: center;
  }

  > * {
    margin: $gutter;
  }

  @media screen and (max-width: $screen-sm) {
    display: block;
    > * {
      margin: $gutter auto;
    }
  }

  &.flex > * {
    flex: 1;
  }
}

// Form

form,
.ui-form {
  // imput from SeizeUI
  .ui-input,
  .ui-tags,
  .ui-select,
  .ui-radio {
    margin-bottom: $gutter;
  }
}

// Table

table.table {
  width: 100%;
  border: 1px solid $grey-100;

  thead {
    color: $grey-500;
    font-weight: 600;

    th {
      padding: $gutter;
    }
  }

  tbody {
    color: $grey-700;
    font-weight: 400;

    td {
      border: 1px solid $grey-100;
      padding: $gutter;
    }
  }
}


// Emoji

img.emoji {
   height: 1em;
   width: 1em;
   margin: 0 .05em 0 .1em;
   vertical-align: -0.1em;
}

// .ui-markdown {
//   line-height: 1.4em;

//   a {
//     color: $blue-200;
//     &:hover {
//       text-decoration: underline;
//     }
//   }

//   h1 {
//     font-size: 1.6em;
//   }

//   p {
//     padding-bottom: $gutter-sm;
//   }

//   table {
//     border-collapse: collapse;
//     margin-bottom: $gutter;
//   }

//   th {
//     font-weight: 600;
//   }

//   th, td {
//     padding: $gutter-sm;
//     border: 1px solid rgba(255,255,255,0.3);
//   }

//   ol, ul, pre {
//     padding-bottom: $gutter-sm;
//   }

//   ol, ul {
//     list-style-position: inside;
//   }

//   ul {
//     list-style-type: disc;
//   }

//   ol {
//     list-style-type: decimal;
//   }

//   img {
//     max-height:100px;
//     max-width:100px;
//   }
// }


// Tooltip
// used in the tooltip directive

// .tooltip-container {
//   .tooltip {
//     opacity: 0;
//     transition: opacity ease .3s;
//   }

//   &:hover {
//     .tooltip {
//       opacity: 1;
//     }
//   }
// }

// .tooltip {
//   $tooltip-height: 7px;
//   $tooltip-width: 5px;

//   position: absolute;
//   top: -30px;
//   background-color: #000;
//   padding: 2px 4px;
//   color: $white;
//   opacity: .8;
//   white-space: nowrap;

//   @include card(1);

//   &:after {
//     content: '';
//     position: absolute;
//     border: solid 0 transparent;
//   }

//   &-top:after {
//     bottom: -$tooltip-height;
//     left: 50%;
//     margin-left: -$tooltip-width;
//     border-top: solid $tooltip-height #000;
//     border-left-width: $tooltip-width;
//     border-right-width: $tooltip-width;
//   }

//   &-right:after {
//     top: 50%;
//     left: -$tooltip-height;
//     margin-top: -$tooltip-width;
//     border-right: solid $tooltip-height #000;
//     border-top-width: $tooltip-width;
//     border-bottom-width: $tooltip-width;
//   }
// }


// Beta

// .beta {
//   background-color: $info-color;

//   padding: $gutter-xs/2 $gutter-xs;
//   border-radius: $gutter-xs;

//   display: inline-block;
//   height: 1rem;
//   line-height: 1rem;
//   vertical-align: middle;

//   &:before {
//     display: inline-block;
//     content: 'beta';
//     text-transform: uppercase;

//     color: $white;

//     font-weight: normal;
//     font-size: .6rem;
//   }
// }


// mobile

@media screen and (max-width: $screen-xs - 1px) {
  .mobile-hidden {
    display: none !important;
  }
}

@media screen and (max-width: $screen-sm - 1px) {
  .hidden-sm {
    display: none !important;
  }
}

@media screen and (min-width: $screen-sm) {
  .show-sm {
    display: none !important;
  }
}
