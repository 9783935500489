














































@import '../style/main';
@import '../style/animations';

.ui-icon {
  user-select: none;

  display: inline-block;
  line-height: 24px;
  vertical-align: middle;

  font-size: 16px;
  width: 24px;
  height: 24px;

  &.ui-icon-md {
    width: 18px;
    height: 18px;
  }

  &.ui-icon-sm {
    width: 16px;
    height: 16px;
  }

  &.ui-icon-lg {
    width: 32px;
    height: 32px;
  }

  &.ui-icon-spin svg {
    animation: spin 2s infinite linear;
  }
}
