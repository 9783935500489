

































































































@import '../style/main';

.ui-link:not(.nostyle) {
  text-decoration: none;
  color: $palette-blue;
  padding-bottom: 1px;
  border-bottom: 0;

  line-height: 1.4em;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid $palette-blue;
  }
}
