







































































































































































@import '../style/main';

.ui-button {
  $btn-height: 2.5rem;
  $btn-height-sm: 2rem;
  $btn-border-size: 0.1rem;
  $btn-min-width: 6rem;

  @mixin hover-active($color) {
    &:hover {
      background-color: lighten($color, 5%);
    }

    &:active,
    &.active,
    &.router-link-active {
      background-color: lighten($color, 10%);
    }
  }

  @mixin hover-active-outline($color) {
    &:hover {
      border: $btn-border-size solid lighten($color, 5%);
    }

    &:active,
    &.active,
    &.router-link-active {
      border: $btn-border-size solid lighten($color, 10%);
    }
  }

  @mixin btn-colored($color) {
    background-color: $color;
    color: $white;
    fill: $white;

    @include hover-active($color);

    &:disabled {
      background-color: lighten($color, 20%);
      @include hover-active(lighten($color, 15%));
    }
  }

  @mixin btn-outlined($color) {
    border: $btn-border-size solid $color;
    color: $color;
    fill: $color;
    height: $btn-height - $btn-border-size * 2;
    line-height: $btn-height - $btn-border-size * 2;

    &.ui-button-sm {
      height: $btn-height-sm - $btn-border-size * 2;
      line-height: $btn-height-sm - $btn-border-size * 2;
    }

    @include hover-active-outline($color);

    &:disabled {
      border: $btn-border-size solid lighten($color, 20%);
      @include hover-active-outline(lighten($color, 15%));
    }
  }

  background: 0 0;
  border: none;
  border-radius: $btn-height / 2;
  color: $dark;

  display: inline-block;
  position: relative;
  padding: 0 $btn-height/2;
  height: $btn-height;
  min-width: $btn-min-width;
  box-sizing: content-box;

  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  line-height: $btn-height;
  letter-spacing: 0;
  overflow: hidden;

  white-space: nowrap;

  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;

  @include text-ellipsis();

  $color: transparentize($white, .95);
  @include hover-active(transparentize($dark, .90));

  &:disabled {
    background-color: lighten($color, 20%);
    @include hover-active(lighten($color, 15%));
    cursor: auto;
  }

  &.ui-button-light {
    $color: transparentize($white, .95);
    @include hover-active($color);
    color: $white;
    fill: $white;

    &:disabled {
      background-color: lighten($color, 20%);
      @include hover-active(lighten($color, 15%));
    }
  }

  &.ui-button-dark {
    $color: transparentize(#000, .75);
    background-color: $color;
    color: $white;
    fill: $white;

    @include hover-active($color);

    &:disabled {
      background-color: lighten($color, 20%);
      @include hover-active(lighten($color, 15%));
    }
  }

  &.primary {
    @include btn-colored($primary-color)
  }

  &.primary-outline {
    @include btn-outlined($primary-color)
  }

  &.secondary {
    @include btn-colored($secondary-color)
  }

  &.secondary-outline {
    @include btn-outlined($secondary-color)
  }

  &.light-outline {
    @include btn-outlined($white)
  }

  &.success {
    @include btn-colored($palette-green)
  }

  &.alert {
    @include btn-colored($palette-red)
  }

  .ui-icon.force-left {
    margin-left: -0.4em;
    margin-right: 0.4em;
  }

  &.ui-button-sm {
    padding: 0 $gutter-sm;
    border-radius: $btn-height-sm / 2;
    height: $btn-height-sm;
    line-height: $btn-height-sm;

    font-size: 12px;
  }

  &.align-left {
    text-align: left;
  }

  &.multiline {
    height: auto;
    white-space: normal;
    line-height: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}
