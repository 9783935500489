












































































@import '~@/assets/style/variables';

.navigation {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &__navbar {
    position: relative;
    min-height: 4em;
    padding: 0 $gutter;
    z-index: 100;
    background-color: $dark;
    color: $white;
    pointer-events: auto;

    display: flex;
    flex-direction: row;
    align-items: center;

    @include card(1);
  }
}
