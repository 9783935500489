









@import '~@/assets/style/variables';

.main-title {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 0;

    border-top: 1px solid rgba(236,232,225,.4);
  }

  .sub-title {
    position: absolute;
    font-size: 2em;
    line-height: 1em;
    left: 0;
    top: 0;
    color: rgba(250, 250, 250, .16);

    text-shadow: none;
  }
}
