
































@import '../style/main';

.ui-form {
  display: inherit;
  flex-direction: inherit;

  .ui-input,
  .ui-radio,
  .ui-select,
  .ui-tags {
    margin-bottom: $gutter-sm;
  }

  &.inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .container-errors {
    min-height: 1.4em;
    margin-bottom: $gutter-sm;
    .text-error {
      font-size: 1em;
    }
  }
}
