



































































































































@import '~@/assets/style/variables';

// Special transition
// name="slide-nav-panel"
// Slide from right to left

.slide-nav-panel-enter-active {
  transition: margin-left .1s ease;
}
.slide-nav-panel-leave-active {
  transition: margin-left .1s ease;
}
.slide-nav-panel-enter, .slide-nav-panel-leave-to {
  margin-left: -220px;
}

.navigation-panel-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: transparentize($dark, .99);
  }
}

.navigation-panel {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 280px;
  pointer-events: auto;

  background-color: $dark;
  @include card(5);

  &__header {
    background-color: $primary-color;
    display: flex;
    height: 4em;

    .ui-logo {
      height: 3.5em;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 0;

    border-bottom: 1px solid $grey-700;

    .ui-button {
      text-align: left;
      border-radius: 0;
      color: $white;

      &:hover, &.router-link-active {
        background-color: $primary-color;
      }
    }

    .menu-title {
      display: inline-block;

      padding: 0 $gutter-sm;
      height: 2em;
      font-size: .8em;
      font-weight: 500;
      line-height: 2em;

      text-transform: uppercase;
      vertical-align: middle;
      letter-spacing: 0;

      &:not(:first-child) {
        margin-top: $gutter-md;
      }

      @include text-ellipsis();
    }
  }
}
