






















































@import '~@/assets/style/variables';

.notfound {
  text-align: center;
  padding: $gutter;
  color: $white;

  h1 {
    font-size: 7em;
  }
}
