















































































































































































































@import '../style/main';
@import '../style/transitions';

.ui-snackbar {
  position: fixed;
  min-width: 150px;
  max-width: 80%;
  min-height: 42px;
  line-height: 42px;
  z-index: 999;
  overflow: visible;

  background-color: $blue-grey-700;
  color: $white;
  border-radius: 5px;

  &__content {
    overflow: hidden;
    @include text-ellipsis();
  }

  padding: $gutter-sm;

  @include card(1);

  display: flex;
  flex-direction: row;

  &.bottom {
    bottom: 0;
    margin-bottom: $gutter;
  }
  &.center {
    margin: 0 auto;
    margin-left: -225px;
    left: 50%;
  }
  &.top {
    top: 0;
    margin-top: $gutter;
  }
  &.left {
    left: 0;
    margin-left: $gutter;
  }
  &.right {
    right: 0;
    margin-right: $gutter;
  }

  &.info {
    background-color: $info-color;
    color: $white;
  }
  &.success {
    background-color: $success-color;
    color: $white;
  }
  &.warn {
    background-color: $warning-color;
    color: $white;
  }
  &.alert {
    background-color: $error-color;
    color: $white;
  }
}
