








@import '~@/assets/style/variables';

.view-container {
  color: $white;

  &__section {
    position: relative;
    overflow: visible;

    padding: $gutter-lg $gutter;
    border-left: 1px solid rgba(236,232,225,.4);

    &:before {
      position: absolute;
      background-color: transparentize($white, .84);
      content: '';
      display: block;
      width: 100%;
      height: 65%;
      left: -101%;
      top: $gutter-lg;
      z-index: 1;
    }
  }
}
